<template>
  <div style="overflow-y: hidden;">
    <template v-if="userManager.hasPermission(Permissions.MENU_NAV_WORK)">
      <carousel-tabs-items
        v-model="model"
        :items="carouselTabsItems"
      />
    </template>
  </div>
</template>

<script>
    import WindowComponent from "@/assets/plugins/mps-window-mobile/WindowComponent";
    import WorkRecord from "@/views/home/WorkRecord";
    import WorkerHomeItem from "@/components/windows/main/WorkerHomeItem";
    import ConsultNote from "@/views/home/ConsultNote.vue";

    export default {
        name: "WorkerHome",
        components: {  },
        extends: WindowComponent,
        mixins: [],
        data() {
            return {
                items: [],
                salesSummary: {},
                pager: undefined,
                loading: false,
              model: "home",
              carouselTabsItems: [
                { text: this.$translate("홈"), value: "home", component: WorkerHomeItem },
                { text: this.$translate("작업일지"), value: "work_record", component: WorkRecord },
                { text: this.$translate("상담노트"), value: "consult_note", component: ConsultNote },
              ],
            }
        },
      watch: {
        model(newValue, oldValue) {
          localStorage.setItem('homeActiveTab', newValue)
        }
      },
      created() {
        const homeActiveTab = localStorage.getItem('homeActiveTab')
        if (homeActiveTab && homeActiveTab !== '' ){
          this.model = homeActiveTab
        }
      },
    }
</script>

<style scoped>
>>> .v-slide-group__content {
  border-bottom: 2px solid #ffffff !important;
}
</style>
